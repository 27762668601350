<template>
    <div class="form-group" 
        v-bind:class="{ find: viewMode == 'find' }"
    >
        <p v-if="field.metadata && field.metadata.description">
            {{ field.metadata.description }}
        </p>
        <v-autocomplete
            v-model="value"
            :items="options"
            item-text="value"
            item-value="key"
            :label="field.name"
            :append-outer-icon="appendIcon()"
            @click:append-outer="handleIconClick"
            v-bind:class="{touched: touched}"
            v-on:change="handleUpdate"
            ></v-autocomplete>
    </div>
</template>
<script>
import { mapMutations, mapState, mapGetters } from 'vuex';

export default {
    props: {
        activeID: String,
        field: Object, //field keys and meta data
        type: String, // field Type data
        isNested: Boolean,
        parentField: Object // field meta if this is in a connected data portal
    },
    data: function() {
        return {
            value: this.$store.getters.getFieldVal(this.field.id, this.activeID),
            touched: false,
        }
    },
    watch: {
        viewMode: function() {
            this.resetValue();
        }
    },
    computed: {
        ... mapState({
            viewMode: state => state.viewMode,
        }),
        ...mapGetters([
            'getEntityPath'
        ]),
        options: function() {
            if (this.field.data_type == 6) {
                const relatedOptions = this.$store.getters.activeFields.related;
                if (relatedOptions && relatedOptions[this.field.id]) {
                    return relatedOptions[this.field.id].map( x => {
                        return {
                        key: x.project_id.toString(),
                        value: x.meta ? x.meta.map(x => x.value).join(" ") : x.project_id
                        }
                    });
                }
            }
            if (this.field.metadata == null || this.field.metadata.options == null) return [];
            return this.field.metadata.options.map( x => {
                return {
                key: x,
                value: x
                }
            });
        }
    },
    methods: {
        ...mapMutations({
           updateField: 'UPDATE_FIELD'
        }),
        appendIcon: function() {
            return this.field.data_type == 6 ? 'mdi-arrow-right' : null;
        },
        handleUpdate(){
            this.updateField({
                projectId: this.activeID,
                id: this.field.id,
                value: this.value
            });
            this.touched = true;
        },
        resetValue() {
            this.value = this.$store.getters.getFieldVal(this.field.id, this.activeID);
            this.touched = false;
        },
        handleIconClick() {
            if (this.field.data_type == 6) {
                const typeId = this.field.relationship_type;
                const path = this.getEntityPath(typeId, parseInt(this.value));
                if (path) this.$router.push(path);
            }
        }
    },
    mounted: function() {
        this.$store.subscribe((mutation) => {
            if (mutation.type == "SET_RECORD") {
                this.resetValue();
            } else if (mutation.type == "SAVE_COMPLETE") {
                this.touched = false;
            }
        });
    }
}
</script>
<style lang="scss" scoped>
    .form-group {
        &.find {
            .input-container {
                position: relative;
                &:after {
                    display: block;
                    position: absolute;
                    content: 'X';
                    height: 20px;
                    width: 20px;
                    right: 5px;
                    top: 5px;
                }
            }
            select {
                    background-color: #d9f6ff;
                }
        }

        select {
            &.touched {
                border: 2px solid #328d32;
                font-weight: bold;
            }
        }
    }
</style>