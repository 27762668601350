<template>
  <div :key="activeProjectType ? activeProjectType.codename : ''">
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ProjectContainer",
  components: {},
  watch: {
    "$route.params.type"(to, from) {
      if (to !== from) {
        this.$store.commit("UPDATE_ACTIVE_TYPE", to);
        this.processPath();
      }
    },
    projectTypes() {
      this.processPath();
    },
  },
  computed: {
    ...mapGetters(["activeProjectType"]),
  },
  methods: {
    ...mapActions(["getProjectFieldsByType"]),
    processPath: function () {
      this.getProjectFieldsByType(this.activeProjectType);
    },
  },
  mounted() {
    this.processPath();
  },
};
</script>
<style lang="scss">
</style>