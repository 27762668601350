<template src="./ToolNav.html">
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
export default {
    name: 'ToolNav',
    data: function() {
        return {
            isSticky: false,
            navOpen: false,
        }
    },
    components: {

    },
    computed: {
        ... mapGetters([
            'viewModeDisplay',
            'activeLayout',
            'activeList',
            'prevItem',
            'nextItem',
            'activeProjectType',
            'userCanCreate',
            'userIsAdmin',
            'activeProjectListPage'
        ]),
        ... mapState({
            focusProjectId: state => state.focusProjectId,
            authenticated: state => state.authenticated,
            viewMode: state => state.viewMode,
            navItems: state => state.projectTypes.filter( x => x.parent_id == null),
            activeTypeCodename: state => state.activeTypeCodename,
            projectLists: state => state.projectLists,
            projectTypesMap: state => state.projectTypesMap,
            pendingUpdates: state => state.pendingUpdates,
        }),
        projectTypeDisplayName: function() {
            return this.$store.getters.activeProjectType ? this.$store.getters.activeProjectType.name : ''
        },
        listingUrl: function() {
            return this.getReturnUrl(this.activeProjectType.codename);
        },
        returnToUrl: function() {
            const returnTo = this.$route.query.returnTo ?? null;
            return this.getReturnUrl(this.activeProjectType.codename, returnTo);
        },
        layoutUrl: function() {
            const activeId = this.$route.params.id ?? null;
            return this.getLayoutUrl(this.activeProjectType.codename, activeId);
        },
        importUrl: function() {
            return this.$store.getters.activeProjectType ? `/dash/${this.$store.getters.activeProjectType.codename}/import` : ''
        },
        findUrl: function() {
            return this.$store.getters.activeProjectType ? `/dash/${this.$store.getters.activeProjectType.codename}/find` : ''
        },
        layoutEditing: {
            get() {
                return (this.$route.name == "Project Layout");
            },
            set() {
                if ((this.$route.name == "Project Layout")) {
                    this.$router.push(this.returnToUrl);
                } else {
                    this.$router.push(this.layoutUrl);
                }
            }
        },
        isDetailDsiplay() {
            return this.$route.name == "Project Detail";
        },
        currentItemDisplay: function() {

            const projectType = this.projectTypesMap[this.activeTypeCodename]
            if (projectType == null) return '';
            const thisList = this.projectLists[projectType.id];
            const list = thisList ? thisList.list || [] : [];
            const total = list ? list.total : 0;
            if (list && list.length && this.$route.name == "Project Detail") {
                // find index of item in list
                const { id } = this.$route.params;
                const index = list.findIndex(x => x.id == id) + 1;
                return `${index} of ${ list.length }`
            }
            if (total == null || total == list.length) return `${list.length} items`;
            return `${list.length} items <br/> ${total} total`;
        }
    },
    mounted: function() {
    },
    methods: {
        ...mapMutations({
            startFindMode: 'START_FIND_MODE',
            startViewMode: 'START_VIEW_MODE',
            setRecord: 'SET_RECORD',
        }),
        ...mapActions([
            'searchProjectRecords',
            'saveLatestLayout',
            'updateProject'
        ]),
        handleScroll(){
                const scrollPosition = window.scrollY;
                if(scrollPosition>=200){
                    this.isSticky = true
                }else if (scrollPosition < 100) {
                    this.isSticky = false
                }
        },
        openNav() {
            this.navOpen = !this.navOpen;
        },
        parseUrl(item) {
            return `/dash/${item.codename}`;
        },
        cancelClick() {
            this.$router.go(-1);
            this.startViewMode();
        },
        findClick() {
          this.startFindMode();
        },
        saveChanges: async function() {
            const update = await this.updateProject(this.focusProjectId);
            if (update != null && this.focusProjectId != update.id) {
                this.$router.push(`/dash/${this.activeProjectType.codename}/${update.id}`);
            }
        },
        saveLayout() {
            let pkg = {
                layout: this.activeLayout.layout
            };
            this.saveLatestLayout(pkg);
        },
        goPrev() {
            if (this.prevItem) {
                const id = this.prevItem.id;
                this.setRecord(this.prevItem);
                this.$router.push(`/dash/${this.activeProjectType.codename}/${id}`)
            }
        },
        goNext() {
            if (this.nextItem) {
                const id = this.nextItem.id;
                this.setRecord(this.nextItem);
                this.$router.push(`/dash/${this.activeProjectType.codename}/${id}`)
            }
        }
    },
    created () {
            //window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
            //window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
<style lang="scss">
</style>