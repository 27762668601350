<template src="./SideNav.html">
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Alert from "@/models/alert";
import eventBus from "@/eventBus";

export default {
    name: 'SideNav',
    data: function() {
        return {
            drawer: null,
            sharingDialog: false,
            shareTo: null,
            workspaceSharedTo: [],
            userRoles: [
                { key: "Viewer", value: 0},
                { key: "Editor", value: 1},
                { key: "Creator", value: 2},
                { key: "Admin", value: 3}
            ]
        }
    },
    components: {
    },
    computed: {
        ... mapGetters([
            'activeList',
            'activeProjectType',
            'getWorkspace',
            'userIsAdmin'
        ]),
        ... mapState({
            authenticated: state => state.authenticated,
            activeWorkspace: state => state.activeWorkspace,
            user: state => state.user,
            viewMode: state => state.viewMode,
            navItems: state => state.projectTypes.filter( x => x.parent_id == null),
        }),
    },
    mounted: function() {
    },
    methods: {
        ...mapActions([
            'setWorkspace'
        ]),
        parseUrl(item) {
            return `/dash/${item.codename}`;
        },
        changeWorkspace: async function(id) {
            await this.setWorkspace(id).then( () => {});
            this.$router.push('/dash/');
        },
        startShare() {
            this.shareTo = null;
            this.sharingDialog = true;
            this.getShared();
        },
        getShared() {
            this.$http.get(`/api/workspaces/${ this.activeWorkspace }/invite`)
                .then((response) => {
                    this.workspaceSharedTo = response.data;
                });
        },
        updateWorkspaceInvite(invite) {
            const model = invite.accepted ? 'user' : 'invite';
            const url = `/api/workspaces/${invite.workspace_id}/${model}/${invite.id}`;
            const body = {
                user_role: invite.user_role
            }
            this.$http.put(url, body)
                .then((response) => {
                    if (response) {
                        const notification = new Alert('Updated permissions');
                        eventBus.$emit('site-event', notification);
                    }
                });
        },
        sendInvite() {
            if (!this.shareTo || this.shareTo == '') return;
            const url = `/api/workspaces/${ this.activeWorkspace }/invite`;
            const pkg = {
                email: this.shareTo
            };
            this.$http.post(url, pkg)
                .then((response) => {
                    if (response.data.success) {
                        this.workspaceSharedTo = [ 
                            ...this.workspaceSharedTo,
                            {
                                email: this.shareTo,
                                accepted: 0
                            }
                        ];
                    }
                    this.shareTo = null;
                });
        }
    },
    created () {
            //window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
            //window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
<style lang="scss" scoped>
    .sidenav-header {
        min-height: 65px;
    }
</style>