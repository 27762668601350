<template>
    <div>
        <v-checkbox  
        v-model="value"
        @change="handleUpdate"
        label="Is Hyperlink"/>
    </div>
</template>
<script>
export default {
    props: ['field'],
    data: function() {
        return {
            value: false,
        };
    },
    methods: {
        handleUpdate(){
            this.$emit('updateFieldMeta', 'isHyperlink', this.value);
        }
    },
    mounted: function() {
        this.value = this.field.metadata ? this.field.metadata.isHyperlink : false;
    }
}
</script>
