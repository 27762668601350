<template>
    <div class="form-group">
        <p v-if="field.metadata && field.metadata.description">
            {{ field.metadata.description }}
        </p>
        <v-row class="mb-3">
            <v-file-input
                disabled
                :label="field.name"
            ></v-file-input>
        </v-row>
    </div>
</template>
<script>
export default {
    props: [
        'field', 'isNested', 'type'
    ],
    data: function() {
        return {
            value: null
        }
    },
    watch: {
    },
    computed: {
    },
    methods: {
    },
    mounted: function() {
    }
}
</script>