<template>
  <div>
    <div v-for="(meta, i) in fieldType.metaComponents" :key="i">
      <div
        :is="meta"
        :field="internalValue"
        v-on:updateFieldAttribute="handleUpdate"
        v-on:updateFieldMeta="handleMetaUpdate"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MetaEditor",
  props: {
    fieldType: { type: Object },
    value: { type: Object },
  },
  data: () => ({
    internalValue: {},
  }),
  created() {
    this.internalValue = this.value;
  },
  methods: {
    handleUpdate: function (key, value) {
      this.$set(this.internalValue, key, value);
      this.$emit('input', this.internalValue);
    },
    handleMetaUpdate: function (key, value) {
      if (this.internalValue.metadata == null) {
        this.internalValue.metadata = {};
      }
      this.$set(this.internalValue.metadata, key, value);
      this.$emit('input', this.internalValue);
    },
  },
};
</script>

<style>
</style>