import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index';

import Home from '../views/Home.vue'
import Plans from '../views/Plans/Plans.vue'
import Features from '../views/Features/Features.vue'
import Login from '../views/Login/Login.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'


import AuthContainer from '../views/AuthContainer.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import ProjectContainer from '../views/ProjectContainer/ProjectContainer.vue'
import ProjectList from '../views/ProjectList/ProjectList.vue'
import ProjectDetail from '../views/ProjectDetail/ProjectDetail.vue'
import ProjectLayout from '../views/ProjectLayout/ProjectLayout.vue'
import ImportData from '../views/ImportData/ImportData.vue'
import Configure from '../views/Configure/Configure.vue'


const checkAuth = async function(to, from, next) {
    const loginStatus = await store.dispatch('getLoginStatus');
    if (loginStatus == null) next({ name: 'Login' });
    next();
}

const ensureData = async function(to, from, next) {
  if (to.params.type !== from.params.type) {
    store.commit('UPDATE_ACTIVE_TYPE', to.params.type)
  }
  if (to.meta.requiresFields) {
    await store.dispatch('ensureProjectLayoutDisplay', to.params.type);
  }
  next();
}

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/plans',
    name: 'Plans',
    component: Plans
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/dash',
    name: 'Auth Dashboard',
    component: AuthContainer,
    beforeEnter: checkAuth,
    meta: { requiresAuth: true, dashTools: true },
    children: [
      {
        path: '/',
        name: 'Dash',
        beforeEnter: ensureData,
        component: Dashboard,
      },
      {
        path: 'configure',
        name: 'Configure',
        beforeEnter: ensureData,
        component: Configure,
      }, {
        path: ':type',
        name: 'Projects',
        beforeEnter: ensureData,
        component: ProjectContainer,
        meta: { requiresFields: true },
        children: [
          {
            path: '/',
            name: 'ProjectList',
            component: ProjectList,
          },
          {
            path: 'layout',
            name: 'Project Layout',
            beforeEnter: ensureData,
            component: ProjectLayout,
            meta: { 
              requiresFields: true
            }
          },
          {
            path: 'import',
            name: 'Import Data',
            beforeEnter: ensureData,
            component: ImportData,
            meta: {
              requiresFields: true
            },
          },
          {
            path: ':id',
            name: 'Project Detail',
            beforeEnter: ensureData,
            component: ProjectDetail,
            meta: { 
              requiresFields: true
            }
          }
        ]
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
