import fieldTypes from "@/data/fieldTypes";
import AttachmentDisplay from '@/components/ListDisplay/AttachmentDisplay';
import LinkDisplay from '@/components/ListDisplay/LinkDisplay';
import TagsDisplay from '@/components/ListDisplay/TagsDisplay';
import ToggleDisplay from '@/components/ListDisplay/ToggleDisplay';


const determineDisplayComponent = (fieldDef) => {
    const fieldType = fieldTypes.find(x => x.id == fieldDef.data_type);
    if (fieldDef.data_type == 8) {
        // attachments
        return AttachmentDisplay;
    }
    if (fieldDef.data_type == 10) {
        // attachments
        return TagsDisplay;
    }
    if (fieldDef.data_type == 1 && fieldDef.metadata) {
        if (fieldDef.metadata.isHyperlink) return LinkDisplay
    }
    if (fieldDef.data_type == 4) {
        return ToggleDisplay;
    }
    if (fieldType && fieldType.isForeignKey) {
        return null;
    }
    return null;
}


export default determineDisplayComponent