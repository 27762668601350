<template>
    <div class="edit-field">
        <v-card-title class="headline font-weight-regular light-blue white--text">
            Field Settings
        </v-card-title>
        <v-card-title>
        </v-card-title>
        <div class="px-6" v-if="editedFieldDef && currentFieldType">
            <meta-editor
                :field-type="currentFieldType"
                v-model="editedFieldDef"
             />
        </div>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="$emit('edit-finish')">
                Close
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="saveChanges">
                Save
            </v-btn>
        </v-card-actions>
         <v-progress-linear
            v-if="loading"
            indeterminate
            color="cyan"
            ></v-progress-linear>
    </div>
</template>
<script>
import axios from 'axios';
import fieldTypes from "@/data/fieldTypes";
import MetaEditorDisplay from '../MetaComponents/MetaEditorDisplay.vue';

export default {
    components: {
        'meta-editor': MetaEditorDisplay
    },
    props: ['field'],
    data: function() {
        return {
            editedFieldDef: null,
            loading: false
        }
    },
    computed: {
        currentFieldDef: function() {
            const fieldDef = this.$store.getters.getFieldDefinition(this.field);
            if (fieldDef) {
                return fieldDef;
            }
            return {};
        },
        currentFieldType: function() {
            const fieldDef = this.$store.getters.getFieldDefinition(this.field);
            if (fieldDef) {
                const fieldType = fieldTypes.find(x => x.id == fieldDef.data_type);
                return fieldType;
            }
            return null;
        }
    },
    methods: {
        saveChanges: function() {
            this.loading = true;
            axios.put(`/api/types/fields/${this.editedFieldDef.id}`, this.editedFieldDef)
                .then((response) => {
                    // update field
                    this.loading = false;
                    this.$emit("edit-finish", response.data.field);
                });
        }
    },
    mounted: function() {
        this.editedFieldDef = { ... this.currentFieldDef };
    }
}
</script>

<style lang="scss">
    .edit-field {
        button.save {
            margin-top: 20px;
        }
        
        label {
            font-weight: bold;
        }
    }
    
</style>