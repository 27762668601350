<template>
    <v-container>
        <h1>Features</h1>
        <p>
            Plansaurus is ready to store whatever data you throw at it.
        </p>
        <v-card class="mb-6">
            <v-card-title>
               Basic Data Fields
            </v-card-title>
            <v-card-text>
                Text fields, date fields, checkboxes, and dropdown boxes are the bread and butter of any database and can handle the majority of your data needs.
            </v-card-text>
        </v-card>
        <v-card class="mb-6">
            <v-card-title>
                Advanced Data Fields
            </v-card-title>
            <v-card-text>
                Related data fields will let you connect a collection of data to another in one-to-one or one-to-many relationships. If you update a record in one place, it is instantly reflected everywhere else in your workspace.
            </v-card-text>
        </v-card>
        <v-card class="mb-6">
            <v-card-title>
                Custom Interfaces
            </v-card-title>
            <v-card-text>
                Every data type has its own interface to edit. Fields can be grouped into different layouts and re-arranged in a drag and drop interface.
            </v-card-text>
        </v-card>
        <v-card class="mb-6">
            <v-card-title>
                Intuitive Searching
            </v-card-title>
            <v-card-text>
                A database is nothing without the ability to search. Search on any combination of fields by entering your search query right in the interface.
            </v-card-text>
        </v-card>
        <v-card class="mb-6">
            <v-card-title>
                Sharing & Collaboration
            </v-card-title>
            <v-card-text>
                Add friends and team-members to your workspace to work together.
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    
}
</script>