<template>
    <div v-if="hasLink">
            <a :href="linkEndpoint"
                target="_blank">
                    <v-icon>
                        mdi-link
                    </v-icon>
            </a>
    </div>
</template>
<script>
export default {
    name: 'LinkDisplay',
    props: [
        'id', 'item', 'column'
    ],
    computed: {
        hasLink() {
            return this.item[this.id] != null;
        },
        linkEndpoint() {
            return this.item[this.id];
        }
    }
}
</script>