import BasicInputEditable from "@/components/ContentEditing/FieldTypes/BasicInput.vue";
import BasicInputMock from "@/components/TemplateEditing/FieldTypes/BasicInputMock.vue";

import DropdownInputEditable from "@/components/ContentEditing/FieldTypes/DropdownInput.vue";
import DropdownInputMock from "@/components/TemplateEditing/FieldTypes/DropdownInput.vue";

import ToggleInputEditable from "@/components/ContentEditing/FieldTypes/ToggleInput.vue";
import ToggleInputMock from "@/components/TemplateEditing/FieldTypes/ToggleInputMock.vue";

import ChildComponentInput from "@/components/ContentEditing/FieldTypes/ChildComponentInput.vue";
import ChildComponentMock from "@/components/TemplateEditing/FieldTypes/ChildComponentMock.vue";

import UploadInputEditable from "@/components/ContentEditing/FieldTypes/UploadInput.vue";
import UploadInputMock from "@/components/TemplateEditing/FieldTypes/UploadInputMock.vue";

import TagInput from "@/components/ContentEditing/FieldTypes/TagInput.vue";

// Metacomponents
import NameCodename from "@/components/TemplateEditing/MetaComponents/NameCodename.vue";
import KeyValueOptions from "@/components/TemplateEditing/MetaComponents/KeyValueOptions.vue";
import OneToOne from "@/components/TemplateEditing/MetaComponents/OneToOne.vue";
import FieldsToDisplay from "@/components/TemplateEditing/MetaComponents/FieldsToDisplay.vue";
import UploadAttributes from "@/components/TemplateEditing/MetaComponents/UploadAttributes.vue";
import FieldDescription from "@/components/TemplateEditing/MetaComponents/FieldDescription.vue";
import IsHyperLinkOption from "@/components/TemplateEditing/MetaComponents/IsHyperlinkOption.vue";
import IsTextAreaOption from "@/components/TemplateEditing/MetaComponents/IsTextAreaOption.vue";


const fieldTypes = [
    {
        id: 1,
        name: "Text Input",
        type: "text",
        layoutComponent: BasicInputMock,
        editingComponent: BasicInputEditable,
        icon: "mdi-format-text",
        description: "Simple text field",
        metaComponents: [
            NameCodename,
            IsHyperLinkOption,
            FieldDescription,
            IsTextAreaOption
        ],
    },
    {
        id: 2,
        name: "Number (integer)",
        type: "number",
        layoutComponent: BasicInputMock,
        editingComponent: BasicInputEditable,
        icon: "mdi-pound",
        description: "Basic numbers, no decimals",
        metaComponents: [
            NameCodename,
            FieldDescription
        ]
    },
    {
        id: 9,
        name: "Number (decimal)",
        type: "number",
        layoutComponent: BasicInputMock,
        editingComponent: BasicInputEditable,
        icon: "mdi-pound",
        description: "More complicated numbers, use this for currency or anything that needs precision",
        metaComponents: [
            NameCodename,
            FieldDescription
        ]
    },
    {
        id: 3,
        name: "Date",
        type: "date",
        layoutComponent: BasicInputMock,
        editingComponent: BasicInputEditable,
        icon: "mdi-calendar",
        description: "Date picker, or date time",
        metaComponents: [
            NameCodename,
            FieldDescription
        ]
    },
    {
        id: 4,
        name: "Toggle Input (Yes / No)",
        layoutComponent: ToggleInputMock,
        editingComponent: ToggleInputEditable,
        icon: "mdi-toggle-switch",
        description: "Basic yes/no field",
        metaComponents: [
            NameCodename,
            FieldDescription
        ]
    },
    {
        id: 5,
        name: "Dropdown (Static)",
        layoutComponent: DropdownInputMock,
        editingComponent: DropdownInputEditable,
        icon: "mdi-form-dropdown",
        description: "Choose an item from a list of static options",
        metaComponents: [
            NameCodename,
            KeyValueOptions,
            FieldDescription
        ]
    },
    {
        id: 6,
        name: "Dropdown (Related Item)",
        layoutComponent: DropdownInputMock,
        editingComponent: DropdownInputEditable,
        isForeignKey: true,
        icon: "mdi-form-dropdown",
        description: "Choose an items from another data source in your project",
        metaComponents: [
            NameCodename,
            FieldDescription,
            OneToOne
        ]
    },
    {
        id: 7,
        name: "Connected Data",
        layoutComponent: ChildComponentMock,
        editingComponent: ChildComponentInput,
        isForeignKey: true,
        icon: "mdi-layers-triple",
        description: "Connect your record to multiple other records (for example: participants, ingredients, bill of materials)",
        metaComponents: [
            NameCodename,
            OneToOne,
            FieldsToDisplay
        ]
    },
    {
        id: 8,
        name: "File / Image Upload",
        layoutComponent: UploadInputMock,
        editingComponent: UploadInputEditable,
        icon: "mdi-file-image",
        description: "Upload images, documents, or other files related to your data",
        metaComponents: [
            NameCodename,
            UploadAttributes,
            FieldDescription
        ]
    },
    {
        id: 10,
        name: "Tags Input",
        type: "text",
        layoutComponent: TagInput,
        editingComponent: TagInput,
        icon: "mdi-format-text",
        description: "Add simple tags/attributes for filtering",
        metaComponents: [
            NameCodename,
            FieldDescription
        ],
    }

];

export default fieldTypes