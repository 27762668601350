<template>
    <router-link :to="destination">
        <v-btn color="secondary" x-small>
            View
            <v-icon x-small>
                mdi-arrow-right
            </v-icon>
        </v-btn>
    </router-link>
    
</template>
<script>
export default {
    name: 'GoToProjectButton',
    props: [
        'id', 'item'
    ],
    computed: {
        destination: function() {
            const goto = this.item.APP_VIEW;
            return  `/dash/${goto.codename}/${goto.id}`;
        }
    },
    methods: {
    }
}
</script>