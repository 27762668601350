<template src="./TemplateSection.html"></template>
<script>
import Zone from "../Zone/TemplateZone";

export default {
    name: 'TemplateSection',
    props: ['item', 'index'],
    components: {
        'layout-zone': Zone
    },
    data() {
        return {
            editWindowOpen: false,
            // fields in edit window
            qtyColumns: 1,
            firstColumnSize: 6,
            metadata: {
                hasGap: true,
                label: null,
                description: null
            }
        }
    },
    computed: {
        hasGap() {
            return this.item.metadata.hasGap;
        },
        drawerWidth() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '100%'
                case 'sm': return '100%'
                default: return 600
            }
        }
    },
    methods: {
        getClass(i) {
            if (this.item.columns == 4) {
                return "col-12 col-md-3";
            } else if (this.item.columns == 3) {
                return "col-12 col-md-4";
            } else if (this.item.columns == 2) {
                const firstCol = `col-12 col-sm-${this.item.columnSize}`;           
                const secCol = `col-12 col-sm-${12 - this.item.columnSize}`;           
                return i == 0 ? firstCol : secCol;
            }
            return "col-12";
        },
        handleUpdateChild(index, item) {
            // update the zone that was modified
            this.item.zones[index] = item;
            // pass new definition up to parent LAYOUT
            this.$emit('updatechild', this.index, this.item);
        },
        handleUpdateLabel() {
            this.$emit('updatechild', this.index, this.item);
        },
        handleUpdateDescription() {
            this.$emit('updatechild', this.index, this.item);
        },
        editSection() {
            this.qtyColumns = this.item.columns;
            this.firstColumnSize = this.item.columnSize;
            Object.assign(this.metadata, this.item.metadata);
            // this.metadata = {
            //     ...this.metadata,
            //     ...this.item.metadata};
            this.editWindowOpen = true;
        },
        saveSection() {
            this.item.setSize(this.qtyColumns, this.firstColumnSize);
            Object.assign(this.item.metadata, this.metadata);
            // this.item.metadata = this.metadata;
            this.$emit('updatechild', this.index, this.item);
            this.editWindowOpen = false;
        }
    }
}
</script>
<style lang="scss">
    .zone-col {
        + .zone-col {
            border-left: 1px solid rgba(200, 200, 200, 0.66);
        }
    }
    
    .section-edit {
        right: -15px;
        top: -5px;
    }
</style>