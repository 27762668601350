<template>
	<div>
		<v-container>
			<v-card>
				<v-card-title>
					<h2>Import Data</h2>
				</v-card-title>
				<v-card-text>
					<div>
						Does your data have column headers?
						<v-switch
							v-model="hasHeaders"
							:label="hasHeaders ? 'Yes' : 'No'"
						></v-switch>
					</div>
					<input type="file" @change="onChange"/>
				</v-card-text>
			</v-card>
			<div v-if="headers.length">
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
							<th class="text-left">
								Column Name
							</th>
							<th class="text-left">
								Migrates To
							</th>
							</tr>
						</thead>
						<tbody>
							<import-field-details 
								v-for="(header, i) in headers"
								:key="header.key"
								@update-field="(e) => handleUpdate(i, e)"
								:header="header"
								:fieldList="activeFields"
								:importItems="items">
							</import-field-details>
						</tbody>
					</template>
				</v-simple-table>
			</div>
			<div>
			<v-btn @click="completeImport">
				Import
			</v-btn>
			</div>
		</v-container>
	</div>
</template>
<script>
import {  
	read as xlsRead, 
	utils as xlsUtils
} from "xlsx";

import ImportFieldDetails from './ImportFieldDetails.vue';
import { mapGetters } from 'vuex';


export default {
    name: 'ImportData',
	components: {
		'import-field-details': ImportFieldDetails
	},
    data: function() {
        return {
			file: null,
			hasHeaders: true,
			headers: [],
			items: []
		}
	},
	computed: {
        ...mapGetters([
            'activeFields',
            'activeProjectType'
        ]),
	},
    methods: {
        onChange(event) {
            this.file = event.target.files ? event.target.files[0] : null;
            if (this.file) {
                const reader = new FileReader();
                reader.onload = (e) => {
					/* Parse data */
					const bstr = e.target.result;
					const wb = xlsRead(bstr, { type: 'binary' });
					/* Get first worksheet */
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];
					/* Convert array of arrays */
					let opts = this.hasHeaders ? {} : { header: "A" };
					const data = xlsUtils.sheet_to_json(ws, opts);
					this.items = data;
					this.headers = Object.keys(data[0]).map((v) => {
						return {
							key: v,
							field: null
						};
					});
                }

                reader.readAsBinaryString(this.file);
            }
        },
		handleUpdate(i, v) {
			console.log(i, v);
			this.$set(this.headers, i, v);
		},
		async completeImport() {
			// loop througn data
			const fieldsToPopulate = this.headers.filter(x => x.field != null);
			for(let i = 0; i < this.items.length; i++ ) {
				const data = this.items[i];
				const importFields = [];
				fieldsToPopulate.forEach((h) => {
					let fieldValue = data[h.key]; // get matching value from spreadsheet
					importFields.push({
						field_id: h.field,
						value: fieldValue
					}); // assign to import set
				});
				console.log(importFields);
			}
		}
    }
}
</script>