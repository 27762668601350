<template>
    <div class="form-group">
        <p v-if="field.metadata && field.metadata.description">
            {{ field.metadata.description }}
        </p>
        <v-switch
            :class="toggleClass"
            inset
            dense
            density="compact"
            color="secondary"
            v-model="value"
            v-on:change="handleUpdate"
            :label="field.name"
        ></v-switch>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';

export default {
    props: {
        activeID: String,
        field: Object, //field keys and meta data
        type: String, // field Type data
        isNested: Boolean,
        parentField: Object // field meta if this is in a connected data portal
    },
    data: function() {
        return {
            value: parseInt(this.isNested ? this.field.value : this.$store.getters.getFieldVal(this.field.id, this.activeID)),
            touched: false
        }
    },
    watch: {
        viewMode: function() {
            this.resetValue();
        },
        $route() {
            this.resetValue();
        },
    },
    computed: {
        ... mapState({
            viewMode: state => state.viewMode,
        }),
        fieldIdForUpdate: function() {
            if (this.isNested) {
                return `${this.parentField.id}_${this.field.id}`;
            }
            return this.field.id;
        },
        toggleClass: function() {
            if (this.viewMode == 'find' && this.value == false) {
                return ['isFalse']
            }
            return [];
        }
    },
    methods: {
        ...mapMutations({
           updateField: 'UPDATE_FIELD'
        }),
        handleUpdate: function(){
            const newVal = (this.viewMode == 'find' && this.searchOperator) ? `${this.searchOperator}${this.value}` : this.value;
            this.dateMenu = false;

            this.updateField({
                projectId: this.activeID,
                id: this.fieldIdForUpdate,
                value: newVal
            });
            this.touched = true;
        },
        resetValue: function() {
            this.value = parseInt(this.isNested ? this.field.value : this.$store.getters.getFieldVal(this.field.id, this.activeID)),
            this.touched = false;
        }
    },
    mounted: function() {
        this.$store.subscribe((mutation) => {
            if (mutation.type == "SET_RECORD") {
                this.resetValue();
            } else if (mutation.type == "SAVE_COMPLETE") {
                this.touched = false;
            }
        });
    }

}
</script>
<style lang="scss" scoped>
    .v-input--switch__thumb {
        border: 5px solid purple;
    }
</style>