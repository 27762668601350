<template>
    <div class="form-group">
        <p v-if="field.metadata && field.metadata.description">
            {{ field.metadata.description }}
        </p>
        <v-text-field
            v-if="!useTextArea"
            :label="field.name"
            :dense="isNested"
            :hide-details="isNested? true : false"
            :placeholder="field.name"
        ></v-text-field>
        <v-textarea 
            v-if="useTextArea"
            :label="field.name"
            :dense="isNested"
            :hide-details="isNested? true : false"
            :placeholder="field.name">

        </v-textarea>
    </div>
</template>
<script>
export default {
    props: [
        'field', 'isNested', 'type'
    ],
    data: function() {
        return {
            value: null
        }
    },
    watch: {
    },
    computed: {
        useTextArea: function() {
            const { metadata } = this.field;
            if (metadata && metadata.isTextArea == true) {
                return true;
            }
            return false;
        },
    },
    methods: {
    },
    mounted: function() {
    }
}
</script>