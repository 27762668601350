<template>
    <div class="form-group">
        <p v-if="field.metadata && field.metadata.description">
            {{ field.metadata.description }}
        </p>
        <v-select :label="field.name" 
            :items="field.metadata.options || []"
            class="form-control">
        </v-select>
    </div>
</template>
<script>
export default {
    props: [
        'field'
    ]
}
</script>