<template>
  <div>
      <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: "AuthContainer",
  components: {
  },
}
</script>
<style lang="scss">
  
</style>