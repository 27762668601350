<template>
    <div class="form-group">
        <p v-if="field.metadata && field.metadata.description">
            {{ field.metadata.description }}
        </p>
        <v-switch
            inset
            :label="field.name"
        ></v-switch>
    </div>
</template>
<script>
export default {
    props: [
        'field'
    ]
}
</script>