<template src="./LayoutArea.html"></template>
<script>
import Vue from 'vue';
import LayoutSection from '@/components/TemplateEditing/Section/TemplateSection';
import Section from '@/models/class.section';


export default {
    props: ['area', 'areaname'],
    name: 'LayoutArea',
    data: function() {
        return {
            targetSectionId: null,
        }
    },
    components: {
        'layout-section': LayoutSection
    },
    methods: {
        newSection(i) {
            const newSectionIndex = i + 1;
            let newArea = {...this.area };
            newArea.sections.splice(newSectionIndex, 0, Section.create(1, null));
            this.updateMe(newArea);
        },
        handleUpdateChild(index, item) {
            let newArea = {...this.area };
            Vue.set(newArea.sections, index, item);
            this.updateMe(newArea);
        },
        updateMe(obj) {
            this.$emit('updateArea', this.areaname, obj);
        }
    }
}
</script>

<style lang="scss">
    .section-container {
        position: relative;
    }
    .section-new, .section-edit {
        position: absolute;
        z-index: 3;
        text-align: center;
        cursor: pointer;
    }
    .section-new {
        left: -15px;
        bottom: -30px;
        position: absolute;
    }

</style>