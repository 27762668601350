<template>
    <div>
        <v-textarea
                type="text"
                label="Field Description (optional)"
                auto-grow
                rows="1"
                v-on:change="handleUpdate"
                v-on:keyup="handleUpdate"
                v-model="value"/>
    </div>
</template>
<script>
export default {
    props: ['field'],
    data: function() {
        return {
            value: '',
        };
    },
    methods: {
        handleUpdate(){
            this.$emit('updateFieldMeta', 'description', this.value);
        }
    },
    mounted: function() {
        this.value = this.field.metadata ? this.field.metadata.description : null;
    }
}
</script>
