<template>
  <v-card>
    <v-card-title>Edit Table Fields </v-card-title>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"></th>
              <th>Table Fields</th>
              <th>Show</th>
            </tr>
          </thead>
          <tbody name="animated-list" is="transition-group">
            <tr
              v-for="(item, i) in localFieldList"
              :key="`${item.uuid}_${item.id}`"
            >
              <td>
                <v-btn
                  x-small plain density="compact"
                  :disabled="i == 0" @click="moveFieldUp(i)">
                  <v-icon small>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                  x-small plain density="compact"
                  :disabled="i == localFieldList.length - 1"
                  @click="moveFieldDown(i)"
                >
                  <v-icon small>mdi-arrow-down</v-icon>
                </v-btn>
              </td>
              <td>{{ item.name }}</td>
              <td>
                <v-switch hide-details density="compact" v-model="item.active" class="mt-0" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            plain
            variant="text"
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            dark
            color="green lighten-1"
            variant="text"
            @click="saveLayout"
          >
            Save
          </v-btn>
        </v-card-actions>

  </v-card>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { mapActions, mapGetters } from "vuex";
import { arrayMoveImmutable } from "array-move";

export default {
  name: "TableEditor",
  data: () => ({
    fieldToAdd: "",
    localFieldList: [],
  }),
  computed: {
    ...mapGetters(["activeProjectType", "activeFields"]),
    activeFieldsforDropdown: function () {
      const f = this.activeFields;
      if (f && f.fields) {
        return f.fields.filter((x) => x.parent == null);
      }
      return [];
    },
  },
  mounted() {
    const activeFields = this.activeProjectType.fieldLayout.fields;
    const allFields = this.activeFields.fields;
    this.localFieldList = this.mapCurrentLayout(activeFields, allFields);
  },
  methods: {
    ...mapActions([
          'modifyListLayout',
      ]),
    mapCurrentLayout(activeFields, allFields) {
      const activeIds = activeFields.map((f) => f.id);
      const active = activeFields.map((x) => {
        const def = this.$store.getters.getFieldDefinition(x.id) || {
          id: x.id,
          name: x.id,
        };
        return { ...def, active: true, uuid: x.uuid };
      });
      const inactive = allFields.map((x) => {
        if (activeIds.indexOf(x.id) != -1) return null;
        return { ...x, active: false, uuid: uuidv4() };
      });
      return [...active, ...inactive.filter((x) => x != null)];
    },
    close() {
      this.$emit('close');
    },
    async saveLayout() {
      const modified = {
        ...this.activeProjectType.fieldLayout,
        fields: this.localFieldList.filter(x => x.active)
      }
      const pkg = {
        type: this.activeProjectType.id,
        layout: modified
      };
      await this.modifyListLayout(pkg);
      this.close();
    },
    moveFieldUp: function (index) {
      const newFieldLayout = arrayMoveImmutable(
        this.localFieldList,
        index,
        index - 1
      );
      this.localFieldList = newFieldLayout;
    },
    moveFieldDown: function (index) {
      const newFieldLayout = arrayMoveImmutable(
        this.localFieldList,
        index,
        index + 1
      );
      this.localFieldList = newFieldLayout;
    },
  },
};
</script>