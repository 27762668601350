<template>
    <div v-if="attachmentArray.length">
        <a v-for="attach in attachmentPrimary"
            :key="attach.id" 
            target="_blank"
            :href="attach.publicPath">
            <v-avatar 
                :title="attach.filename"
                rounded
                class="mr-1"
                color="primary">
                <v-img :src="attach.previewPath"  v-if="attach.previewPath"/>
                <v-icon v-else dark>
                    mdi-file-image
                </v-icon>
            </v-avatar>
        </a>
    </div>
</template>
<script>
export default {
    name: 'AttachmentDisplay',
    props: [
        'id', 'item', 'column'
    ],
    computed: {
        attachmentArray: function() {
            const attachments = this.item[this.id] || [];
            return [ ...attachments ].splice(0, 3);
        },
        attachmentPrimary: function() {
            return this.attachmentArray.length ? [ this.attachmentArray[0] ] : null;
        }
    }
}
</script>