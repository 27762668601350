<template>
    <div>
        <v-form :disabled="isDisabled">
            <layout-section 
                v-for="(child, i) in item.sections" 
                :activeID="activeID"
                :item="child"
                :index="i"
                :key="child.id">
            </layout-section>
        </v-form>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import Section from '../Section/Section';
export default {
    props: {
        activeID: String,
        item: Object
    },
    components: {
        'layout-section': Section
    },
    computed: {
        ... mapState({
            viewMode: state => state.viewMode,
        }),
        ...mapGetters([
            'userCanEdit'
        ]),
        isDisabled: function() {
            if (this.userCanEdit) return false;
            if (this.viewMode == 'find') return false;
            return true;
        }
    }
}
</script>
<style lang="scss">
    .theme--light.v-label--is-disabled {
        color: rgba(0, 0, 0, 0.6);
    }
    .theme--light.v-input--is-disabled {
        input, textarea {
            color: rgba(0, 0, 0, 0.87);
        }
    }
</style>