import { v4 } from 'uuid';

export default class Alert {
    id = '';
    type = 'success';
    text = '';
    constructor(_text, _type) {
        this.text = _text;
        if (_type) {
            this.type = _type;
        }
        this.id = v4();
    }
}