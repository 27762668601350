<template src="./ProjectDetail.html"></template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import EditingArea from '@/components/ContentEditing/EditingArea/EditingArea'
import Project from '@/models/class.project'

export default {
    name: 'ProjectDetail',
    data: function() {
        return {
            activeID: null,
            items: [
            { title: 'Dashboard', icon: 'mdi-view-dashboard' },
            { title: 'Photos', icon: 'mdi-image' },
            { title: 'About', icon: 'mdi-help-box' },
            ],
            right: null,
        }
    },
    watch: {
        $route() {
            this.processPath();
        // react to route changes...
        },
        activeProjectType() {
            this.processPath();
        }
    },
    components: {
        'editing-area': EditingArea
    },
    computed: {
        ...mapGetters([
            'activeFields',
            'activeLayout',
            'activeList',
            'activeProjectType'
        ]),
        ...mapState({
            projectTypes: state => state.projectTypes,
            pendingFind: state => state.pendingFind,
            viewMode: state => state.viewMode,
        }),
    },
    methods: {
        ...mapActions([
            'getProjectListById',
            'getProjectFields',
            'getProjectLayout',
            'getProjectRecord',
        ]),
        ...mapMutations({
           setRecord: 'SET_RECORD',
           startFind: 'START_FIND_MODE'
        }),
        processPath: async function() {
            this.activeID = this.$route.params.id;
            if (this.activeID == "new" && this.activeProjectType) {
                const p = Project.New(this.activeProjectType.id);
                this.activeID = `new_${ uuidv4() }`;
                p.id = this.activeID;
                this.setRecord(p);
            } else if (this.activeID == "find" && this.activeProjectType) {
                this.startFind();
            } else if (this.activeProjectType && this.activeID) {
                this.queryList();
            }
			if (this.activeList == null || this.activeList.list == null) {
                // do we have the list of items for this type? if not, lets get them
				this.getProjectListById(this.activeProjectType.id);
			}
        },
        queryList: async function() {
            this.getProjectRecord(this.activeID).then(() => {
            });
        },

        saveHandler(e) {
            if (e.ctrlKey && e.keyCode === 83) {
                // CTRL+S Listener
                e.preventDefault();
                this.saveChanges();
            }
        }
    },

    mounted: function() {
        this.processPath();

        window.addEventListener("keydown", this.saveHandler);
    },
    
    beforeDestroy() {
        window.removeEventListener('keydown', this.saveHandler);
    },
    

}
</script>