<template>
    <tr>
        <td>
            {{ header.key }}
        </td>
        <td>
            <v-autocomplete
                autocomplete
                :items="fieldList.fields"
                item-text="name"
                item-value="id"
                v-model="selectedField"
                >
            </v-autocomplete>
            <div v-if="header.config && header.config.hasOptions">
                <div v-for="(item, i) in header.config.mapping" :key="item.from">
                    {{ item.from }}
                     -> 
                     <v-select
                        @change="(v) => updateMapping(i, v)"
                        :items="dropdownOptions"
                        :value="item.to"
                        >
                    </v-select>
                </div>
            </div>
        </td>
    </tr>
</template>
<script>
export default {
    name: 'ImportFieldDetails',
    props: {
        header: {
            type: Object
        },
        fieldList: {
            type: Object
        },
        importItems: {
            type: Array
        }
    },
    data() {
        return {
            options: {}
        }
    },
    computed: {
        selectedField: {
            get() {
                return this.header.field;
            },
            set(v) {
                const fieldDef = this.fieldList.fields.find(x => x.id == v);
                const config = this.generateConfig(fieldDef);
                const update = {
                    ...this.header,
                    field: v,
                    config
                }
                this.$emit('update-field', update);
            }
        },
        fieldDefinition: function(){
            return this.fieldList.fields.find(x => x.id == this.selectedField);
        },
        dropdownOptions: function() {
            if (this.fieldDefinition != null) {
                if (this.fieldDefinition.data_type == 5) { // dropdown, pull from metadata
                    return this.fieldDefinition.metadata.options || [];
                }
            }
            return [];
        }
    },
    methods: {
        generateConfig(fieldDef) {
            if (fieldDef.data_type == 5) {
                // static dropdown, lets populate some options
                // get distinct values dropdown optons
                let { options } = fieldDef.metadata;
                const distinct = this.getDistinctInput();
                return {
                    data_type: 5,
                    hasOptions: true,
                    options,
                    mapping: distinct
                };
            }
        },
        getDistinctInput() {
            const distinct = this.importItems.reduce((acc, item) => {
                const v = item[this.header.key];
                if (acc[v] == null) {
                    acc[v] = 1;
                }
                return acc;
            }, {});
            return Object.keys(distinct).map(d => {
               return {
                   from: d,
                   to: null
               } 
            });
        },
        updateMapping(i, v) {
            console.log(i, v);
            const config = { ... this.header.config };
            config.mapping[i].to = v;
            const update = {
                ...this.header,
                config
            };
            console.log(update);
            this.$emit('update-field', update);
        }
    }
}
</script>