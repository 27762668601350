<template src="./Section.html"></template>
<script>
import Zone from "../Zone/Zone";

export default {
    name: 'Section',
    props: {
        activeID: String,
        item: Object,
        index: Number
    },
    components: {
        'layout-zone': Zone
    },
    computed: {
        hasGap() {
            return this.item.metadata.hasGap;
        }
    },
    methods: {
        getClass(i) {
            if (this.item.columns == 4) {
                return "col-12 col-md-3";
            } else if (this.item.columns == 3) {
                return "col-12 col-md-4";
            } else if (this.item.columns == 2) {
                const firstCol = `col-12 col-sm-${this.item.columnSize}`;           
                const secCol = `col-12 col-sm-${12 - this.item.columnSize}`;           
                return i == 0 ? firstCol : secCol;
            }
            return "col-12";
        },
        handleUpdateChild(index, item) {
            let newItem = { ... this.item };
            // update the zone that was modified
            newItem.zones[index] = item;
            // pass new definition up to parent LAYOUT
            this.$emit('updatechild', this.index, newItem);
        }
    }
}
</script>
<style lang="scss">
    .zone-col {
        + .zone-col {
            border-left: 1px solid rgba(200, 200, 200, 0.66);
        }
    }
</style>