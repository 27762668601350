import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

import api from '@/util/api';

import viewModes from "../data/viewModes";
import Layout from "@/models/class.layout";
import Project from '@/models/class.project';
import ProjectType from '@/models/class.projecttype';

const refreshWindow = 90000;

const refreshValidator = function(fetch, loading, lastUpdate, window) {
	if (fetch) return true; // return if already loading
	if (loading) return true;
	if (Date.now() - lastUpdate < (window || refreshWindow)) return true;
	return false;
}

const processRecordChildren = function(childRecords) {
	return childRecords.map(x => Project.FromData(x) );
}

export default new Vuex.Store({
	state: {
		authLogin: null, // promise for login lookup
		authenticated: false,
		user: null,
		activeWorkspace: null,

		activeTypeCodename: '',

		focusProjectId: null, // id of primary viewport project
		activeProject: {}, //dictionary of projects being worked on
		pendingUpdates: {},
		pendingFind: {},

		// objects to track various project data
		projectTypes: [],
		projectTypesMap: {
			name: {},
			id: {}
		},
		projectFields: {},
		projectLists: {},
		projectLayouts: {},

		viewMode: viewModes.VIEW,
		templateEditor: {
			layout: null,
			field: null,
		}
	},
	mutations: {
		START_LOGIN: function(state, prom) {
			state.authLogin = prom;
		},

		SET_LOGIN: function(state, user) {
			state.authLogin = null;
			state.authenticated = true;
			state.user = user;
		},

		SET_WORKSPACE: function(state, workspace) {
			state.activeWorkspace = workspace;
		},

		/* TYPES */
		SET_TYPES: function (state, typesData) {
			// set TYPES to be key'ed off of type ID
			// const mappedIDs = types.reduce((obj, x) => {
			// 	obj[x.id] = x;
			// 	return obj;
			// }, {});

			const types = typesData.map( x => new ProjectType(x));

			// set TYPES to be key'ed off of type URL
			const mappedNames = types.reduce((obj, x) => {
				obj[x.codename] = x;
				return obj;
			}, {});
			state.projectTypesMap = mappedNames;
			state.projectTypes = types;
		},
		
		UPDATE_ACTIVE_TYPE: function(state, typeName) {
			//const activeType = state.projectTypesMap[typeName];
			state.activeTypeCodename = typeName;
		},

		/* PROJECTS */
		LOADING_LIST: function (state, pkg) {
			// { id, fetch }
			const { id , fetch } = pkg;
			let currentList = state.projectLists[id] || {};
			let newList = {
				...currentList,
				loading: true,
				fetch: fetch
			};
			Vue.set(state.projectLists, id, newList);
		},

		SET_LIST: function (state, pkg) {
			const { id, list, total } = pkg;
			const listProcessed = list.map(x => Project.FromData(x));
			Vue.set(state.projectLists, id, {
				loading: false,
				fetch: null,
				lastUpdate: Date.now(),
				list: listProcessed,
				total: total
			});
			state.viewMode = viewModes.VIEW;
		},


		/* FIELDS */
		LOADING_FIELDS: function (state, pkg) {
			const { id, fetch } = pkg;
			let currentFields = state.projectFields[id] || {};
			let newList = {
				...currentFields,
				loading: true,
				fetch
			};
			Vue.set(state.projectFields, id, newList);
		},

		SET_FIELDS: function (state, pkg) {
			const { id, fields, related } = pkg;
			Vue.set(state.projectFields, id, {
				loading: false,
				lastUpdate: Date.now(),
				fields: fields,
				related,
				fetch: null
			});
		},

		
		UPDATE_FIELD_LAYOUT(state, pkg) {
			const { type, layout} = pkg;
			const index = state.projectTypes.findIndex(x => x.id == type);
			if (index >= 0) {
				const newType = {
					... state.projectTypes[index],
					fieldLayout: layout
				};

				Vue.set(state.projectTypes, index, newType)
			}
		},

		EDIT_LAYOUT_FIELD: function (state, field) {
			state.templateEditor.field = field;
		},

		PUSH_FIELD: function(state, pkg) {
			const { id, field } = pkg;
			if (state.projectFields[id]) {
				let currentFields = state.projectFields[id];
				currentFields.fields.push(field);
				Vue.set(state.projectFields, id, currentFields);
			}
		},

		UPDATE_FIELD_DEFINITION: function(state, pkg) {
			const { id, field } = pkg;
			let currentFields = state.projectFields[id];
			let fieldIndex = currentFields.fields.findIndex(x => x.id == field.id);
			currentFields.fields[fieldIndex] = field;
			Vue.set(state.projectFields, id, currentFields);
		},

		/* LAYOUTS */
		LOADING_LAYOUT: function (state, pkg) {
			const { id, fetch } = pkg;
			let currentLayout = state.projectLayouts[id] || {};
			let newLayout = {
				...currentLayout,
				loading: true,
				fetch
			};
			Vue.set(state.projectLayouts, id, newLayout);
		},
		
		SET_LAYOUT: function (state, pkg) {
			const { id, layout, related } = pkg;
			Vue.set(state.projectLayouts, id, {
				fetch: null,
				loading: false,
				lastUpdate: Date.now(),
				layout: new Layout(layout),
				related: related
			});
		},

		MODIFY_LAYOUT_AREA: function (state, pkg) {
			const { type, area, areaName } = pkg; 
			let currentLayout = state.projectLayouts[type.id].layout;
			console.log(area);
			Vue.set(currentLayout, areaName, area);
		},

		SET_RECORD: function(state, project) {
			if (state.viewMode != viewModes.VIEW) {
				state.viewMode = viewModes.VIEW;
			}
			state.focusProjectId = project.id;
			Vue.set(state.activeProject, project.id, Project.FromData(project));
		},

		UPDATE_FIELD(state, pkg) {
			const { projectId, id, value } = pkg;
			if (state.viewMode == viewModes.FIND) {
				if (state.pendingFind == null) state.pendingFind = {};
				Vue.set(state.pendingFind, id, value);
			} else {
				let projectRef = {};
				if (state.pendingUpdates[projectId] != null) projectRef = state.pendingUpdates[projectId];
				projectRef[id] = value;
				Vue.set(state.pendingUpdates, projectId, projectRef);
			}
		},

		CLEAR_UPDATE_FIELD(state, pkg) {
			const { projectId, id } = pkg;
			if (state.viewMode == viewModes.FIND) {
				if (state.pendingFind == null) state.pendingFind = {};
				Vue.delete(state.pendingFind, id);
			} else {
				let projectRef = {};
				if (state.pendingUpdates[projectId] != null) projectRef = state.pendingUpdates[projectId];
				delete projectRef[id];
				if (Object.keys(projectRef).length > 0) {
					Vue.set(state.pendingUpdates, projectId, projectRef);
				} else {
					Vue.delete(state.pendingUpdates, projectId);
				}
				console.log(state.pendingUpdates);
			}
		},

		SAVE_COMPLETE(state) {
			state.pendingUpdates = {};
		},

		// VIEWS
		START_FIND_MODE(state) {
			if (state.viewMode != viewModes.FIND) {
				state.pendingFind = {};
				state.viewMode = viewModes.FIND;
			}
		},

		START_VIEW_MODE(state) {
			if (state.viewMode != viewModes.VIEW) {
				state.pendingFind = {};
				state.viewMode = viewModes.VIEW;
			}
		}
	},
	actions: {
		async getLoginStatus({ commit, dispatch }) {
			// if logged in, return user
			if (this.state.authenticated) return this.state.user;
			// if already looking up user, return promise
			if (this.state.authLogin) return this.state.authLogin;
			// otheriwse, lets check login status
			const lookup = await axios.get('/signin/status')
				.then(res => {
					if (res.data && res.data.user) {
						commit('SET_LOGIN', res.data.profile);
						commit('SET_WORKSPACE', res.data.workspace);
						dispatch('getTypes');
					}
					return res.data.user;
				});
			commit('START_LOGIN', lookup);
			return lookup;
		},

		getTypes({ commit }) {
			return axios.get("/api/types")
				.then(result => {
					commit('SET_TYPES', result.data.types);
					return 1;
				});
		},

		setWorkspace({ commit, dispatch }, type) {
			commit('SET_WORKSPACE', type);
			return dispatch('getTypes');
		},

		getProjectListById({ commit }, id) {
			// pull in latest list
			if (id == null) return;
			const searchParams = {
				type: id
			};
			const fetch = api.getProjects(searchParams)
				.then(result => {
					commit('SET_LIST', {
						id: id,
						list: result.data.list,
						total: result.data.total,
					});
				});

			commit('LOADING_LIST', {
				id,
				fetch
			});
			
			return fetch;
		},

		getProjectFieldsByType({ commit }, type) {
			const { id } = type;
			if (this.state.projectFields[id]) {
				const { fetch, lastUpdate, loading } = this.state.projectFields[id];
				if (refreshValidator(fetch, loading, lastUpdate)) return; 
			}
			const fetchNew = axios.get(`/api/types/${id}/fields`)
				.then(result => {
					commit('SET_FIELDS', {
						id: id,
						fields: result.data.fields,
						related: result.data.related
					});
				});
			commit('LOADING_FIELDS', {
				id: type.id,
				fetchNew
			});
		},

		createField({ commit }, pkg) {
			const { id } = this.getters.activeProjectType;

			return axios.post(`/api/types/${id}/fields`, pkg)
				.then(result => {
					let fieldPush = result.data.field;
					commit('PUSH_FIELD', { id, field: fieldPush}); // send field to the fieldList
					return fieldPush;
				});
		},

		getProjectLayoutByType({ commit }, type) {
			const { id } = type;
			if (this.state.projectLayouts[id]) {
				const { fetch, lastUpdate, loading } = this.state.projectLayouts[id];
				if (refreshValidator(fetch, loading, lastUpdate)) return;
			}
			const fetchNew = axios.get(`/api/types/${id}/layout`)
				.then(result => {
					commit('SET_LAYOUT', {
						id: id,
						layout: result.data.layout,
						related: result.data.related
					});
				});
			commit('LOADING_LAYOUT', {
				id: id,
				fetch: fetchNew 
			});
		},

		async ensureProjectLayoutDisplay({ dispatch }, type) {
			// looks up the fields and the layout for a project type
			console.log(this.state.activeWorkspace)
			let searchType = this.state.projectTypesMap[type];
			if (searchType == null) {
				await dispatch('getTypes');
				searchType = this.state.projectTypesMap[type];
			}
			const fieldLookup = dispatch('getProjectFieldsByType', searchType);
			const layoutLookup = dispatch('getProjectLayoutByType', searchType);
			return Promise.all([fieldLookup, layoutLookup]);
		},

		saveLatestLayout(_, pkg) {
			const { id } = this.getters.activeProjectType;
			return axios.post(`/api/types/${id}/layout`, pkg)
				.then(result => {
					return result.data;
				})
		},

		modifyListLayout({ commit }, pkg) {
			const { id } = this.getters.activeProjectType;
			const { type, layout } = pkg;
			return axios.post(`/api/types/${id}/fieldlayout`, { layout })
				.then(result => {
					commit('UPDATE_FIELD_LAYOUT', {
						type,
						layout
					})
					return result.data;
				})
		},

		getProjectRecord({ commit }, id) {
			return api.getProjectById(id)
				.then(response => {
					const children = processRecordChildren(response.data.children);
					commit('SET_RECORD', {
						...response.data.project,
						children
						}
					);
					return true;
				});
		},

		searchProjectRecords({ commit }) {
			const searchParams = {
				type: this.getters.activeProjectType.id,
				...this.state.pendingFind
			};
			return api.getProjects(searchParams)
				.then(response => {
					console.log(response);
					const pkg = {
						id: this.getters.activeProjectType.id,
						list: response.data.list,
						total: response.data.total,
					};
					commit('START_VIEW_MODE');
					commit('SET_LIST', pkg);
					return true;
				});
		},

		updateProject({ commit }, id) {
			const targetProject = this.state.activeProject[id] || {};
			console.log(targetProject);
			const updates = this.state.pendingUpdates[id] || {};

			let fields = Object.keys(updates).map(x => {
				return {
					field_id: x,
					value: updates[x]
				};
			});
			if (fields.length == 0) return;

			const updatedProject = {
				...targetProject,
				fields
			};
			let url = '/api/projects';
			if (id .toString().indexOf("new") == -1) {
				url = `/api/projects/${id}`;
			}
			return axios.post(url, updatedProject)
				.then((response) => {
					commit('SAVE_COMPLETE');
					//commit('SET_RECORD', updatedProject);
					return response.data;
				})
				.catch((err) => {
					console.log(err);
				});
		}
	},
	getters: {
		viewModeDisplay: (state) => {
			if (state.route.name == 'Project Layout') {
				return 'Layout';
			} else if (state.viewMode == viewModes.FIND) {
				return 'Find';
			} else {
				return 'Edit';
			}
		},
		userWorkspace: (state) => {
			const { activeWorkspace, user } = state;
			if (activeWorkspace == null || user == null) return {};
			const thisWorkspace = user.workspaces.find(x => x.id == activeWorkspace);
			return thisWorkspace || {};
		},
		userCanEdit: (_, getters) => {
			const { userWorkspace } = getters;
			console.log(getters);
			if (userWorkspace.user_role > 0) return true;
			return false;
		},
		userCanCreate:  (_, getters) => {
			const { userWorkspace } = getters;
			if (userWorkspace.user_role > 1) return true;
			return false;
		},
		userIsAdmin:  (_, getters) => {
			const { userWorkspace } = getters;
			if (userWorkspace.user_role >= 4) return true;
			return false;
		},
		activeProjectType: state  => {
			const t = state.projectTypes.find(x => x.codename == state.activeTypeCodename);
			return t || {};
		},
		activeList: (state, getters) => {
			const { id } = getters.activeProjectType;
			if (id && state.projectLists[id]) {
				return state.projectLists[id];
			}
			return {};
		},
		activeItemIndex: (state, getters) => {
			const { id } = state.route.params;
			if (id) {
				const list = getters.activeList.list || [];
				const index = list ? list.findIndex(x => x.id == id) : -1;
				return index > -1 ? index : null;
			}
		},
		prevItem: (state, getters) => {
			const { activeItemIndex, activeList } = getters;
			if (activeItemIndex >= 0 && activeList && activeList.list) {
				if (activeItemIndex == 0) return null;
				const prev = activeList.list[activeItemIndex-1];
				return prev;
			}
			return null;
		},
		nextItem: (state, getters) => {
			const { activeItemIndex, activeList } = getters;
			if (activeItemIndex >= 0 && activeList && activeList.list) {
				if (activeItemIndex >= activeList.length - 1) return null;
				const next = activeList.list[activeItemIndex + 1];
				return next;
			}
			return null;
		},
		activeFields: state => {
			const apt = state.projectTypes.find(x => x.codename == state.activeTypeCodename) || {};
			const { id } = apt;

			if (id && state.projectFields[id]) {
				return state.projectFields[id];
			}
			return {};
		},
		activeProjectListPage: state => {
			return state.activeTypeCodename ? `/dash/${state.activeTypeCodename}` : '/dash';
		},
		activeLayout: state => {
			const apt = state.projectTypes.find(x => x.codename == state.activeTypeCodename) || {};
			const { id } = apt;

			if (id && state.projectLayouts[id]) {
				return state.projectLayouts[id];
			}
			return {};
		},
		getFieldVal: (state) => (id, projectId) => {
			let f = null;
			if (state.viewMode == viewModes.FIND) {
				f = state.pendingFind[id];
			} else {
				let project = state.activeProject[projectId] || new Project();
				let field = project.fields.find(x => x.field_id == id);
				f = field ? field.value : f;
			}
			return f;
		},
		getFieldValues: (state) => (id, projectId) => {
			// like getFieldVal but can return an array of options
			let f = [];
			if (state.viewMode == viewModes.FIND) {
				f = state.pendingFind[id];
			} else {
				const project = state.activeProject[projectId] || new Project();
				const values = project.fields.filter(x => x.field_id == id);
				return values.map(x => x.value);
			}
			return f;
		},
		getRelatedFieldVal: (state) => (projectIdFrom, fieldIdFrom, projectIdTo, fieldIdTo) => {
			const apt = state.projectFields[projectIdFrom] || {};
			// get related fields from project type
			const { related } = apt;
			if (related && related[fieldIdFrom]) {
				const relatedOption = related[fieldIdFrom].find(y => y.project_id == projectIdTo);
				if (relatedOption && relatedOption.meta) {
					// find matching fields in the meta object
					const match = relatedOption.meta.find(x => x.field_id == fieldIdTo);
					return match ? match.value : relatedOption.meta[0].value;
				}
				return projectIdTo;
			}
			return {};
		},
		getFieldArrayVal: (state) => (id, projectId) => {
			let f = [];
			if (state.viewMode == viewModes.FIND) {
				f = state.pendingFind[id] || [{}];
			} else {
				const project = state.activeProject[projectId] || new Project();
				if (project.children) {
					let projectIDs = project.fields.filter(x => x.field_id == id).map(y => parseInt(y.value));
					f = project.children.filter(x => projectIDs.indexOf(x.id) > -1);
				}
			}
			return f;
		},
		getFieldFiles: (state) => (id, projectId) => {
			let f = null;
			if (state.viewMode == viewModes.FIND) {
				f = state.pendingFind[id];
			} else {
				const project = state.activeProject[projectId] || new Project();
				let files = project.files.filter(x => x.field_id == id);
				f = files || [];
			}
			return f;
		},
		getFieldDefinition: (state) => (fid) => {
			const apt = state.projectTypes.find(x => x.codename == state.activeTypeCodename) || {};
			const { id } = apt;

			if (state.projectFields[id] && state.projectFields[id].fields) {
				return state.projectFields[id].fields.find(x => x.id == fid);
			}
			return {};
		},
		getFieldsByTypeId: (state) => (id) => {
			const relatedTypeCode = state.projectTypes.find(x => x.id == id);
			if (relatedTypeCode != null) {
				const relatedFields = state.projectFields[relatedTypeCode.id];
				if (relatedFields && relatedFields.fields) {
					return relatedFields.fields;
				}
			}
			return [];
		},
		getWorkspace: (state) => {
			const ws = state.activeWorkspace;
			return ws != null ? state.user.workspaces.find(x => x.id == ws) : {};
		},
		getEntityPath: (state) => (typeId, id) => {
			const thisType = state.projectTypes.find(x => x.id == typeId);
			if (thisType == null) return null;
			return `/dash/${thisType.codename}/${id}`;
		},
	},
	modules: {
	}
})