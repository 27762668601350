import { v4 as uuidv4 } from 'uuid';

export default class ProjectType {
    id = 0;
    parent_id = null;
    name = '';
    singularName = '';
    codename = '';
    menu_order = 0;
    fieldLayout = {};
    qty = 0;

    constructor(t) {
        if (t != null) {
            this.id = t.id;
            this.parent_id = t.parent_id;
            this.name = t.name;
            this.singularName = t.singularName;
            this.codename = t.codename;
            this.menu_order = t.menu_order || 0;
            this.qty = t.qty;
            this.fieldLayout = t.fieldLayout || {};
            if (t.fieldLayout.fields) {
                t.fieldLayout.fields.forEach((v, i)=>{
                    if (v.uuid == null) {
                        t.fieldLayout.fields[i].uuid = uuidv4();
                    }
                });
            } else {
                t.fieldLayout.fields = [];
            }
        }
    }
}