<template src="./ProjectList.html"></template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import convertListToTable from '@/util/convertListToTable'
import GoToProject from '@/components/ListDisplay/GoToProject'
import determineDisplayComponent from '@/data/determineDisplayComponent'
import TableEditor from './components/TableEditor';

export default {
    name: 'ProjectList',
    data: function() {
        return {
            show: false,
            loadingList: false,
            fieldConfigureModal: false,
        }
    },
    components: {
        'goto': GoToProject,
        'table-editor': TableEditor,
    },
    computed: {
        ...mapState({
            projectTypes: state => state.projectTypes,
        }),
        ...mapGetters([
            'activeList',
            'activeFields',
            'activeProjectType',
            'getRelatedFieldVal',
        ]),
        hasFields() {
            const fields = this.activeFields?.fields ?? [];
            return  fields.length > 0;
        },
        headers: function() {
            const fieldLayout = this.$store.getters.activeProjectType.fieldLayout || {};
            const fieldColumns = fieldLayout.fields.map( x => {
                const f = this.$store.getters.getFieldDefinition(x.id) || {};
                const displayComponent = determineDisplayComponent(f);
                const foreignKeyType = f.relationship_type;
                return  {
                    customField: true,
                    text: f ? f.name : x.id,
                    value: x.id.toString(),
                    data_type: f.data_type,
                    displayComponent,
                    foreignKeyType,
                    relatedKey: f.related_keys,
                    metadata: f.metadata
                }
            });
            const base_headers = [{
                    text: '',
                    value: 'APP_VIEW',
                    sortable: false,
                    displayComponent: GoToProject
            }];
            if (fieldColumns.length == 0) {
                base_headers.push({
                    text: 'ID',
                    value: 'ID',
                });
            }
            return [
                ...base_headers,
                ... fieldColumns,
            ]
        },
        dynamicColumns: function() {
            return this.headers.filter(y => y.displayComponent != null);
        },
        items: function() {
            const { list }  = this.$store.getters.activeList;
            const fieldHeaders = this.headers || [];
            return convertListToTable(list, fieldHeaders, this.activeProjectType);
        },
        layoutUrl: function() { return  `/dash/${this.activeProjectType.codename}/layout`; },
    },
    methods: {
        ...mapActions([
        "getProjectListById",
        ]),
        parseUrl(project) {
            return `${this.activeProjectType.codename}/${project.id}`;
        },
        editFields: function() {
            this.fieldConfigureModal = true;
        },
        queryList() {
            this.loadingList = true;
            this.getProjectListById(this.activeProjectType.id).then(() => {
                this.loadingList = false;
            });
        },
    },
    mounted() {
        this.queryList();
    }
}
</script>
<style lang="scss">
    .project-list-table {
        table {
            tr td {
                padding-bottom: 10px;
            }

            colgroup col:first-of-type {
                width: 50px;
            }
        }
    }

    .animated-list {
        &-move {
            transition: transform 0.25s;
        }
        &-enter-active, &-leave-active {
            transition: opacity .5s;
        }
        &-enter, &-leave-to {
            opacity: 0;
        }
    }
</style>