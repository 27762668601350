import { v4 as uuidv4 } from 'uuid';
import Zone from "@/models/class.zone";

export default class Section {
    style = "full";
    columns = 1;
    columnSize = 6;
    zones = [new Zone()];
    metadata = {
        hasGap: true
    };

    constructor(obj = {}) {
        this.id = obj.id || uuidv4();
        this.style = obj.style || "full";
        this.hasGap == (obj.hasGap == null) ? true : obj.hasGap;
        if (obj.columns == null) {
            // fallback to column style
            if (this.style == "full") {
                this.columns = 1;
            } else if (this.style == "33-33-33") {
                this.columns = 3;
            } else {
                this.columns = 2;
                
            }
        } else {
            this.columns = obj.columns;
        }
        if (obj.columnSize) {
            this.columnSize = obj.columnSize;
        } else if (this.columns == 2) {
            if (this.style == "50-50") this.columnSize = 6;
            if (this.style == "66-33") this.columnSize = 8;
            if (this.style == "33-66") this.columnSize = 4;
        }
        if (obj.metadata) {
            Object.assign(this.metadata, obj.metadata);
        }
        if (obj && obj.zones) {
            this.zones = obj.zones.map( x => {
                return new Zone(x || {});
            })
        }
    }

    static create(columns, size) {
        let s =  new this();
        s.columns = columns;
        for(var i=1; i<columns; i++) {
            s.zones.push(new Zone());
        }
        if (columns == 2) {
            s.columnSize = size;
        } else {
            s.columnSize = null;
        }
        return s;
    }

    getFields() {
        return this.zones.flatMap(z => z.getFields() );
    }

    setSize(qtyColumns, firstColumnSize) {
        const lastIndex = qtyColumns - 1;
        if (qtyColumns < this.zones.length) {
            // example: old section had 4 columns, updated to have 1 column
            // move items in 3 and 4 to zone z
            for(var i = lastIndex + 1; i < this.zones.length; i++) {
                // add components from existing slot
                const components = this.zones[i].components;
                this.zones[lastIndex].components.push(...components);
            }
            this.zones = this.zones.slice(0, qtyColumns);
        } else if (qtyColumns > this.zones.length) {
            // add more zones
            const newQty = qtyColumns - this.zones.length;
            console.log(newQty);
            for(var j = 0; j < newQty; j++) {
                this.zones.push(new Zone());
            }
            
        }
        this.columns = qtyColumns;
        this.columnSize = firstColumnSize;
        console.log(this);
    }
}