<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-10">
                    <layout-area 
                        v-if="activeLayout && activeLayout.layout"
                        v-on:updateArea="handleUpdateArea"
                        areaname="primaryArea"
                        :area="activeLayout.layout.primaryArea">
                    </layout-area>
                </div>
            </div>
            <div class="row" v-if="fieldsNotInUse && fieldsNotInUse.length">
                <h2>Unused fields:</h2>
                <template-zone index="-1" 
                    :item="fieldNotInUseObject"/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import LayoutArea from '@/components/TemplateEditing/LayoutArea/LayoutArea';
import TemplateZone from '@/components/TemplateEditing/Zone/TemplateZone';

export default {
    name: 'ProjectLayout',
    components: {
        'layout-area': LayoutArea,
        'template-zone': TemplateZone
    },
    data: function() {
        return {
            activeType: null,
            loading: true
        }
    },
    computed: {
        ...mapGetters([
            'activeFields',
            'activeLayout',
            'activeProjectType',
            'activeProjectListPage'
        ]),
        ...mapState({
            projectTypes: state => state.projectTypes
        }),
        layoutUrl: function() { return  `/dash/${this.activeType}/layout`; },
        fieldsInUse: function() {
            const { layout } = this.activeLayout;
            if (layout == null) return [];
            const sections = layout.primaryArea.sections;
            return sections.flatMap(s => {
                if (s.getFields) {
                    return s.getFields();
                }
            });
        },
        fieldsNotInUse: function() {
            const allFields = this.activeFields.fields || [];
            const inUse = this.fieldsInUse.filter(x => x != null) || [];
            const inUseDictionary = inUse.reduce((o, c) => { o[c.id] = 1; return o; }, {})
            return allFields.filter(x => inUseDictionary[x.id] == null && x.parent == null);
        },
        fieldNotInUseObject: function() {
            const fields =  this.fieldsNotInUse || [];
            const fieldIds = fields.map(x => {
                return { id: x.id };
            });
            return {
                components: fieldIds
            };
        }
    },
    watch: {
        $route(to, from) {
            if (to.params.type !== from.params.type) {
                this.processPath();
            }
        },
        projectTypes() {
            this.processPath();
        }
    },
    methods: {
        ...mapActions([
            'getProjectFields',
            'getProjectLayout',
            'saveLatestLayout'
        ]),
        ...mapMutations({
           modifyArea: 'MODIFY_LAYOUT_AREA'
        }),
        processPath: async function() {
            this.activeType = this.$route.params.type;
            if (this.activeType != null) {
                // this.getProjectLayout(this.activeType);
            }
        },
        handleUpdateArea(name, obj) {
            let update = {
                type: this.activeProjectType,
                areaName: name,
                area: obj
            };
            this.modifyArea(update);
            this.saveLayout();
            console.log("Saving area");
        },
        saveLayout() {
            let pkg = {
                layout: this.activeLayout.layout
            };
            this.saveLatestLayout(pkg);
        }
    },
    mounted: function() {
        this.processPath();
    }
}
</script>