<template>
  <div>
    <v-card-title class="headline font-weight-regular light-blue white--text">
      New Field
    </v-card-title>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">  </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content flat step="1">
            <v-list>
              <v-list-item-group mandatory v-model="fieldTypeIndex">
                <v-list-item
                  dense
                  color="secondary"
                  v-for="field in fieldTypes"
                  :key="field.id"
                  :value="field.id"
                >
                  <v-list-item-icon>
                    <v-icon>{{ field.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <h4>
                      {{ field.name }}
                    </h4>
                    {{ field.description }}
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-card-actions>
                <v-btn text> Cancel </v-btn>
                <v-spacer/>
                <v-btn color="primary" @click="e1 = 2"> Continue </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="2">
            <meta-editor
                v-if="selectedFieldType != null"
                :field-type="selectedFieldType"
                v-model="newFieldDef"
             />
            <v-card-actions>
                <v-btn text @click="el = 1"> Back </v-btn>
                <v-spacer/>
                <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="3">
            All set! Click below to finish
            <v-card-actions>
                <v-btn text> Back </v-btn>
                <v-spacer/>
                <v-btn color="primary" @click="submit"> Create </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="cyan"
    ></v-progress-linear>
  </div>
</template>

<script>
import fieldTypes from "@/data/fieldTypes";
import MetaEditorDisplay from '../MetaComponents/MetaEditorDisplay.vue';

export default {
  props: ["loading"],
    components: {
        'meta-editor': MetaEditorDisplay
    },
  data: function () {
    return {
      e1: 1,
      fieldTypeIndex: 0,
      newFieldDef: {}
    };
  },
  computed: {
    fieldTypes: () => fieldTypes,
    selectedFieldType() {
      return this.fieldTypes.find(f => f.id == this.fieldTypeIndex);
    }
  },
  methods: {
    submit() {
      if (this.newFieldDef.fieldName == "") return;
      const t = fieldTypes.find(f => f.id == this.fieldTypeIndex);

      this.$emit("createField", {
        name: this.fieldName,
        data_type: t.id,
        ...this.newFieldDef,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  h4 {
    margin-bottom: 0;
  }
  .field-icon {
    text-align: center;
  }
}
</style>