export const mixins = {
    methods: {
      getReturnUrl(codename, id) {
        if (id != null) {
          return `/dash/${codename}/${id}`;
        }
        return `/dash/${codename}`;
      },
      getLayoutUrl(codename, returnToId) {
        const returnTo = returnToId != null ? `?returnTo=${returnToId}` : '';
        return `/dash/${codename}/layout${returnTo}`;
      },
      createNewUrl(codename) {
        return `/dash/${codename}/new`;
      }
    }
};

