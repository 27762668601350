<template>
    <v-switch
        readonly
        dense 
        inset
        color="secondary"
        :input-value="value" />
</template>
<script>
export default {
    name: 'ToggleDisplay',
    props: [
        'id', 'item'
    ],
    computed: {
        value() {
            return (this.item[this.id] ?? 0) == "1";
        }
    }
}
</script>