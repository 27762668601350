<template>
    <div class="form-group"
        v-bind:class="{ find: viewMode == 'find' }">
        <p v-if="field.metadata && field.metadata.description">
                {{ field.metadata.description }}
        </p>
        <div class="input-container">
            <v-combobox
                v-if="viewMode != 'find'"
                :label="field.name"
                :dense="isNested"
                :placeholder="field.name"
                v-model="value"
                @change="handleUpdate"
                chips
                multiple
                deletable-chips
                append-icon=""
                >
                </v-combobox>
                <v-text-field v-else
                    v-model="findValue"
                    :label="field.name"
                    :dense="isNested"
                    v-bind:class="{touched: touched}"
                    v-on:change="handleUpdate"
                    v-on:keyup="handleUpdate"
                    v-on:keyup.enter="handleSubmit"
                    />
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'TagInput',
    props: {
        activeID: String,
        field: Object, //field keys and meta data
        type: String, // field type data string
        isNested: Boolean,
        parentField: Object // field meta if this is in a connected data portal
    },
    data: function() {
        return {
            dateMenu: false,
            findValue: '',
            value: this.isNested ? this.field.value : this.$store.getters.getFieldValues(this.field.id, this.activeID),
            touched: false
        }
    },
    watch: {
        viewMode: function() {
            this.resetValue();
        },
        $route() {
            this.resetValue();
        },
    },
    computed: {
        ... mapState({
            viewMode: state => state.viewMode,
        }),
        fieldIdForUpdate: function() {
            if (this.isNested) {
                return `${this.parentField.id}_${this.field.id}`;
            }
            return this.field.id;
        }
    },
    methods: {
        ...mapMutations({
           updateField: 'UPDATE_FIELD'
        }),
        handleUpdate: function(){
            // find out which fields are added/removed
            const base =  this.isNested ? this.field.value : this.$store.getters.getFieldValues(this.field.id, this.activeID);
            if (this.viewMode == 'find') {
                this.updateField({
                    projectId: this.activeID,
                    id: this.fieldIdForUpdate,
                    value: this.findValue
                });
                return;
            }
            const baseValues =  [...base];
            const added = [];
            this.value.forEach(x => {
                const i = baseValues.indexOf(x);
                if (i > -1) {
                    // matches, remove from list
                    baseValues.splice(i, 1);
                } else {
                    // new item, put in added
                    added.push(x);
                }
            });
            // whatever is left over was removed
            const removed = [ ...baseValues];
            this.updateField({
                projectId: this.activeID,
                id: this.fieldIdForUpdate,
                value: {
                    removed,
                    added
                }
            });
            this.touched = true;
        },
        resetValue: function() {
            this.value = this.isNested ? this.field.value : this.$store.getters.getFieldValues(this.field.id, this.activeID),
            this.touched = false;
        }
    },
    mounted: function() {
        this.$store.subscribe((mutation) => {
            if (mutation.type == "SET_RECORD") {
                this.resetValue();
            } else if (mutation.type == "SAVE_COMPLETE") {
                this.touched = false;
            }
        });
    }
}
</script>
<style lang="scss" scoped>
    .input-container {
        display: flex;
    }
</style>