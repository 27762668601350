<template>
  <div class="home">
    <v-sheet color="white" class="hero">
      <v-container class="py-16">
        <v-row class="pt-8 pb-4"
        >
          <v-col
            cols="12"
            md="8"
          >
            <h1 class="mb-4">
              Your business is not a <span>spreadsheet</span>.
            </h1>
            <h4 class="subheading mb-4">
              Build your custom software in minutes with no-code required.
            </h4>
            <v-btn color="primary" class="mt-4">
              Sign Up for free
              <v-icon>
                mid-arrow-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet class="py-6" color="indigo darken-3" dark>
      <v-container>
        <v-row>
          <v-col class="col-12 col-md-6">
            <h2 class="mb-2">
              Online collaboration for all of your needs.
            </h2>
            <p>
              Keep all of your team's data in one place: projects, notes, contacts - anything goes. We don't care if you're tracking business leads or holiday pet costumes.
            </p>
            <p>
              Quit wasting time in spreadsheets and sending documents back and forth, build the interface of your dreams and get the work day today.
            </p>
            <p>
              Watch the demo video to see how it works
            </p>
          </v-col>
          <v-col class="col-12 col-md-6">
            <v-skeleton-loader
                  class="mx-auto"
                  max-width="300"
                  type="card"
                ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="primary" class="py-6">
      <v-container>
        <v-row class="">
          <v-col class="col-12 col-md-4 d-flex flex-column">
            <v-card color="white" class="pa-4 flex">
              <h3 class="text-decoration-underline mb-2">
                Drag & Drop Your UI
              </h3>
              <p>
                Define the properties you want and start populating your data
                <br/>
                Need to add more fields later? Not a problem.
              </p>
            </v-card>
          </v-col>
          <v-col class="col-12 col-md-4 d-flex flex-column">
            <v-card color="white" class="pa-4 flex">
              <h3 class="text-decoration-underline mb-2">
              Search & Sort
            </h3>
            <p>
              Hit "Find" and type what you're looking for. 
              <br/>
              Search by text, date range, checkbox or any combination of properties.
            </p>
            </v-card>
          </v-col>
          <v-col class="col-12 col-md-4 d-flex flex-column">
            <v-card color="white" class="pa-4 flex">
              <h3 class="text-decoration-underline mb-2">
                Customizable
              </h3>
              <p>
                Build your interface with drag and drop tools.
                <br/>
                Upload files for reference later or link data to other records to keep your data nice and clean. 
              </p>
            </v-card>
          </v-col>
          <v-col cols="12 text-center">
            <v-btn color="indigo darken-4" dark to="/features">
              - See all features -
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet class="py-6">
      <v-container color="white" class="text-center">
        <v-row>
          <v-col class="col-12 col-md-8 offset-md-2">
            <h2 class="mb-2">Get Started Today
            </h2>
            <p>
              Personal plans are free to try and get started - just sign up with your email.
            </p>
            <p>
                Upgrade at any point in the future if you need more space or want to start collaborating.
            </p>
            <v-btn color="primary" class="mr-4">
              See Plans
            </v-btn>
            <v-btn color="black" dark>
              Sign Up
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home'
}
</script>
<style lang="scss">
  .hero {
    background-size: cover;
    position: relative;
    background: url("/img/home/pattern.jpg");
    background-size: contain;
    background-repeat: repeat;

    .container {
      position: relative;
      z-index: 2;
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      background:-webkit-gradient(linear,left top, left bottom,color-stop(0, rgba(246,249,251,.65)),to(#f6f9fb));
      background: -webkit-linear-gradient(top,rgba(246,249,251,.65) 0,#f6f9fb);
      background: linear-gradient(180deg,rgba(246,249,251,.65) 0,#f6f9fb);
      z-index: 1;
    }
  }
  
  h1 {
    font-size: 2.75rem;
  }

  h2 {
    font-size: 1.4rem;
  }
</style>