<template>
    <v-chip-group column>
        <v-chip small color="primary" v-for="tag in activeTags" :key="tag">
            {{ tag }}
        </v-chip>
    </v-chip-group>
</template>
<script>
export default {
    name: 'TagsDisplay',
    props: [
        'id', 'item', 'column'
    ],
    computed: {
        activeTags() {
            let a =  this.item[this.id] || [];
            return a.filter((x, i) => i < 3);
        }
    }
}
</script>